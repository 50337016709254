<template>
    <footer>
        <hr>
        <div class="container-fluid">
            <div class="footer">
                <div class="footer-image">
                    <img src="/assets/footer.png" alt="Kiel şehrinin logosu">
                </div>
                <ul class="footer-info">
                    <li><a href="/datenschutz" title=" Gizlilik politikasına">Veri koruma</a></li>
                    <li><a href="/impressum" title=" Baskıya">Künye</a></li>
                </ul>
            </div>
            <div class="btn-container">
                <button @click="showFeedbackModal" class="btn btn-hover feedback">
                    Geri bildirim
                </button>
                <a href="https://bildungsangebote-kiel.de" class="btn btn-hover login" target="_blank">Kuruluşlar için giriş</a>
            </div>
        </div>
    </footer>
</template>

<script>
    import { bus } from '@/main';

    export default {
        name: 'Footer',
        methods: {
          showFeedbackModal() {
            bus.$emit('show-feedback-modal', true);
        }
    }
}
</script>
<style lang="scss" scoped="">
    @import '@/scss/_variables.scss';

    footer{

        .footer-image {
            width: 350px;
            height: auto;
            position: absolute;
            left: 0;
        }

        @media (max-width: 991px) {
            display: none;
        }

        a,.link{
            color: #000;
            cursor: pointer;

            &:hover{
                color: $primary;
                text-decoration: none;
            }
            &:focus-visible {
                color: $primary;
                text-decoration: none;
                border: none !important;
            }
        }

        .btn-container {
            display: flex;

            @media(max-width:1200px){
                position: absolute;
                bottom: 150px;
                right: 20px;

                .feedback {
                    margin-right: 10px;
                }
            }
        }
        
        .login, .feedback {
           max-width: 250px;
           min-width: 250px;

           &:focus-visible {
            border: 2px dashed #21B5EA !important;
            border-radius: 0px 0px 0px 20px !important;
        }
    }
}

hr {
    margin-bottom: 35px;
    color: #00183E;
    border: 1px solid #00183E;
}
</style>